import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Layout from '../../../layout/layout';
import Dashboard from './dashboard';
import { faChartSimple, faTruckTow } from '@fortawesome/pro-solid-svg-icons';
import { useTheme } from '@uturn/ui-kit';
import { useEffect } from 'react';

const navigation = [{ label: 'Dashboard', icon: faChartSimple, href: '/' }];

const router = createBrowserRouter([
	{
		element: (
			<Layout
				module={{ title: 'Operations', icon: faTruckTow }}
				navigation={navigation}
			/>
		),
		children: [
			{
				path: '/',
				element: <Dashboard />,
				handle: {
					crumb: () => 'Dashboard',
				},
			},
		],
	},
]);

const Router = () => {
	const { setColor } = useTheme();
	useEffect(() => {
		setColor('operations');
	}, []);
	return <RouterProvider router={router} />;
};

export default Router;
