import type { PropsWithChildren } from 'react';

const Overlay: React.FC<PropsWithChildren> = ({ children, ...props }) => (
	<div
		className="fixed bg-black/50 z-[999] inset-0 flex justify-center items-center text-white font-bold"
		{...props}
	>
		{children}
	</div>
);

export default Overlay;
