import Router from './routes/router';
import { useTheme } from '@uturn/ui-kit';
import { useEffect } from 'react';
import HttpClientProvider from './http/http-client-provider.tsx';

const FinanceApp = () => {
	const { setColor } = useTheme();
	useEffect(() => {
		setColor('finance');
	}, []);
	return (
		<HttpClientProvider>
			<Router />
		</HttpClientProvider>
	);
};

export default FinanceApp;
