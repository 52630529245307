import { createBrowserRouter, UIMatch, RouterProvider } from 'react-router-dom';
import Layout from '../../../layout/layout';
import Detail from './organization/detail';
import Overview from './organization/overview';
import Dashboard from './dashboard';
import OrganizationErrorPage from './organization/error';
import {
	faChartSimple,
	faDollarSign,
	faSitemap,
} from '@fortawesome/pro-solid-svg-icons';

const navigation = [
	{
		label: 'Dashboard',
		icon: faChartSimple,
		href: '/',
	},
	{
		label: 'Organizations',
		icon: faSitemap,
		href: '/organizations',
	},
];

const router = createBrowserRouter([
	{
		element: (
			<Layout
				module={{ title: 'Finance', icon: faDollarSign }}
				navigation={navigation}
			/>
		),
		children: [
			{
				path: '/',
				element: <Dashboard />,
				handle: {
					crumb: () => 'Dashboard',
				},
			},
			{
				path: '/organizations',
				handle: {
					crumb: () => 'Organizations',
				},
				children: [
					{
						index: true,
						element: <Overview />,
					},
					{
						path: ':id',
						element: <Detail />,
						errorElement: <OrganizationErrorPage />,
						handle: {
							crumb: (data: UIMatch) => data.params.id,
						},
					},
				],
			},
		],
	},
]);

const Router = () => <RouterProvider router={router} />;

export default Router;
