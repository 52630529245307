import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import SignIn from './auth/sign-in';

const router = createBrowserRouter([
	{
		path: '/',
		element: <SignIn />,
	},
]);

const PublicRoutes = () => <RouterProvider router={router} />;

export default PublicRoutes;
