import { Helmet } from 'react-helmet-async';
import { FC, PropsWithChildren } from 'react';

type Props = PropsWithChildren & {
	title?: string;
};

const Page: FC<Props> = ({ title, children }) => {
	return (
		<>
			<Helmet title="Organizations" />
			<div className="flex flex-col h-full">
				{title && (
					<h1 className="text-4xl font-extrabold tracking-tight lg:text-5xl mb-6">
						{title}
					</h1>
				)}
				{children}
			</div>
		</>
	);
};

export default Page;
