import { Helmet } from 'react-helmet-async';

const Dashboard = () => (
	<>
		<Helmet title="Dashboard" />
		<h1 className="text-4xl font-extrabold tracking-tight lg:text-5xl mb-5">
			Dashboard Finance
		</h1>
		<p>
			Lorem ipsum dolor sit amet consectetur adipisicing elit. Non adipisci
			consequatur nulla nemo ipsam. Cum dolor quaerat nobis in doloremque maxime
			voluptatem, ipsa rerum distinctio omnis quis minus similique aut.m
		</p>
	</>
);

export default Dashboard;
