import { faCircleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { Icon } from '@uturn/ui-kit';

const ErrorPage = () => (
	<div className="h-full flex justify-center items-center">
		<div className="flex flex-col gap-2">
			<Icon className="text-red-600" size="2xl" icon={faCircleExclamation} />
			An unkown error occured, please try again later
		</div>
	</div>
);

export default ErrorPage;
