import {
	faArrowLeft,
	faArrowRight,
	IconDefinition,
} from '@fortawesome/pro-solid-svg-icons';
import { Button, Icon, cn } from '@uturn/ui-kit';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';

const ROOT = '/';

type Props = {
	items: { label: string; icon: IconDefinition; href: string }[];
	module: {
		title: string;
		icon: IconDefinition;
	};
};

const Sidebar = ({ items, module }: Props) => {
	const [isOpen, setIsOpen] = useState(
		localStorage.getItem('isOpen') === 'open',
	);

	const handleSetIsOpen = (open: boolean) => {
		setIsOpen(open);
		localStorage.setItem('isOpen', open ? 'open' : 'closed');
	};

	const onClickHandler = () => handleSetIsOpen(!isOpen);

	return (
		<aside
			className={`bg-white border-r shadow h-full flex flex-col sticky top-0 ${isOpen ? 'w-[300px]' : 'w-auto'}`}
		>
			<div className="min-h-12 flex items-center justify-center px-6 gap-1">
				<Icon
					icon={module.icon}
					className="text-white bg-primary p-1 rounded-sm"
					fixedWidth
				/>
				{isOpen && (
					<h2 className="font-semibold uppercase text-xl">{module.title}</h2>
				)}
			</div>
			<div className="px-2 py-6 space-y-1 flex-1">
				{items.map((item) => (
					<NavLink
						key={item.label}
						end={item.href === ROOT}
						to={item.href}
						className={({ isActive }) =>
							cn(
								'flex w-full hover:bg-muted px-3 h-10 rounded-sm items-center gap-2 ring-offset-0 transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-0',
								isActive ? 'bg-accent text-primary' : 'text-black',
							)
						}
					>
						<Icon
							icon={item.icon}
							fixedWidth
							className={isOpen ? '' : 'mx-auto'}
						/>
						{isOpen && <span className="text-black">{item.label}</span>}
					</NavLink>
				))}
			</div>
			<div className="p-2 mt-auto">
				<Button
					variant="ghost"
					className={`flex w-full px-3 h-10 rounded-sm items-center gap-2 cursor-pointer ${isOpen ? 'justify-start' : 'justify-center'}`}
					tabIndex={0}
					onClick={onClickHandler}
				>
					<Icon
						icon={isOpen ? faArrowLeft : faArrowRight}
						className="text-muted-foreground"
					/>
					{isOpen && <span>Collapse</span>}
				</Button>
			</div>
		</aside>
	);
};

export default Sidebar;
