import './sentry/instrument';
import '@uturn/design-tokens/base.css';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { ClerkProvider } from '@clerk/clerk-react';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { LicenseManager, ThemeProvider } from '@uturn/ui-kit';
import App from './app';

const PUBLISHABLE_KEY = import.meta.env.VITE_CLERK_PUBLISHABLE_KEY;
if (!PUBLISHABLE_KEY) {
	throw new Error('Missing Publishable Key');
}

LicenseManager.setLicenseKey(import.meta.env.VITE_AG_GRID_LICENSE_KEY);

createRoot(document.getElementById('root')!).render(
	<StrictMode>
		<HelmetProvider>
			<Helmet titleTemplate="%s | Backoffice" title="Backoffice" />
			<ClerkProvider publishableKey={PUBLISHABLE_KEY} afterSignOutUrl="/">
				<ThemeProvider defaultMode="light" defaultColor="finance">
					<App />
				</ThemeProvider>
			</ClerkProvider>
		</HelmetProvider>
	</StrictMode>,
);
