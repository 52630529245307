import { faCircleNotch } from '@fortawesome/pro-solid-svg-icons';
import { Icon } from '@uturn/ui-kit';

const Loader = () => (
	<div className="h-full flex justify-center items-center">
		<Icon className="text-green-600" size="2xl" icon={faCircleNotch} spin />
	</div>
);

export default Loader;
