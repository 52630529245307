import {
	getOrganizations,
	GetOrganizations200DataItem,
} from '@uturn/api/backoffice/v1';
import { AgGridReact, GridOptions, IServerSideDatasource } from '@uturn/ui-kit';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

const PAGE_SIZE = 50;

const Table = () => {
	const navigate = useNavigate();

	const serverSideDatasource: IServerSideDatasource = useMemo(
		() => ({
			getRows: async ({ api, request, success, fail }) => {
				try {
					const pageSize = api.paginationGetPageSize();
					const startRow = request.startRow ?? 0;

					const page = Math.ceil(startRow / pageSize);

					const { data: organizations } = await getOrganizations({
						page: page + 1,
						size: pageSize,
						name:
							request.filterModel && 'name' in request.filterModel
								? request.filterModel.name.filter
								: undefined,
						orgNumber:
							request.filterModel && 'orgNumber' in request.filterModel
								? request.filterModel.orgNumber.filter
								: undefined,
					});

					if (organizations.data.length === 0) {
						api.showNoRowsOverlay();
					} else {
						api.hideOverlay();
					}

					success({
						rowData: organizations.data,
						rowCount: organizations.metaData.count,
					});
				} catch (err) {
					console.error(err);
					fail();
				}
			},
		}),
		[],
	);

	const gridOptions: GridOptions<GetOrganizations200DataItem> = {
		defaultColDef: {
			flex: 1,
			suppressFloatingFilterButton: true,
			suppressHeaderFilterButton: true,
			suppressHeaderMenuButton: true,
			suppressHeaderContextMenu: true,
			sortable: false,
		},
		columnDefs: [
			{
				field: 'name',
				filter: 'agTextColumnFilter',
				floatingFilter: true,
				filterParams: {
					filterOptions: ['contains'],
				},
			},
			{
				field: 'orgNumber',
				headerName: 'Organization number',
				filter: 'agNumberColumnFilter',
				floatingFilter: true,
				filterParams: {
					filterOptions: ['contains'],
				},
			},
		],
		rowModelType: 'serverSide',
		serverSideDatasource,
		pagination: true,
		paginationPageSize: PAGE_SIZE,
		cacheBlockSize: PAGE_SIZE,
		paginationPageSizeSelector: false,
		onRowClicked: (event) => navigate(`/organizations/${event.data?.id}`),
	};
	return (
		<AgGridReact
			{...gridOptions}
			className="ag-theme-quartz align-baseline z-0"
		/>
	);
};

export default Table;
