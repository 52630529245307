import { useRouteError } from 'react-router-dom';
import { AxiosError } from 'axios';
import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { Icon } from '@uturn/ui-kit';

const ErrorPage = () => {
	const error = useRouteError();

	if (error instanceof AxiosError) {
		if (
			error.status === 400 &&
			error.response?.data.code === 'RESOURCE_NOT_FOUND'
		) {
			return (
				<div className="h-full flex justify-center items-center">
					<div className="flex flex-col gap-2">
						<Icon
							className="text-yellow-600"
							size="2xl"
							icon={faTriangleExclamation}
						/>
						Resource not found
					</div>
				</div>
			);
		}
	}

	throw error;
};

export default ErrorPage;
