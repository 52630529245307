import { OrganizationList, useUser } from '@clerk/clerk-react';
import Header from '../layout/header/header';

const NoMemberships = () => {
	return (
		<div>You are not a member of a team. Please contact your manager.</div>
	);
};

export default function NoBackofficeTeam() {
	const { user } = useUser();

	return (
		<div className="w-full min-h-screen">
			<Header title="Backoffice" />
			<main className="flex p-6 h-auto items-center justify-center">
				{!user?.organizationMemberships?.length ? (
					<NoMemberships />
				) : (
					<OrganizationList hidePersonal afterSelectOrganizationUrl="/" />
				)}
			</main>
		</div>
	);
}
