import { UserButton, OrganizationSwitcher } from '@clerk/clerk-react';

type Props = {
	title?: string;
};

const Header = ({ title }: Props) => (
	<nav className="flex sticky bg-gray-50 shadow z-50 top-0 justify-start items-center h-12 px-6">
		{title && <h1 className="text-lg font-bold">{title}</h1>}
		<div className="flex gap-2 items-center ml-auto">
			<OrganizationSwitcher hidePersonal afterSwitchOrganizationUrl="/" />
			<UserButton />
		</div>
	</nav>
);

export default Header;
