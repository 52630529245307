import { Helmet } from 'react-helmet-async';

const Dashboard = () => {
	return (
		<>
			<Helmet title="Dashboard" />
			<div>Ops dashboard</div>
		</>
	);
};

export default Dashboard;
