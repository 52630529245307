import {
	getOrganizationsIdInvoicesInvoiceLines,
	GetOrganizationsIdInvoicesInvoiceLines200DataItem,
	PutInvoicesInvoiceLinesStatuses400,
	PutInvoicesInvoiceLinesStatuses403,
	PutInvoicesInvoiceLinesStatuses500,
	useGetInvoicesInvoiceLinesStatuses,
	usePutInvoicesInvoiceLinesStatuses,
} from '@uturn/api/finance/v1';
import { useGetOrganizationsId } from '@uturn/api/backoffice/v1';
import {
	AgGridReact,
	Button,
	Form,
	FormControl,
	FormField,
	FormItem,
	IServerSideSelectionState,
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
	sonner,
} from '@uturn/ui-kit';
import { FC, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import Overlay from '../../../../../components/overlay/overlay';
import { SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import Table from './table';
import { type AxiosError } from 'axios';
import Page from '../../../../../layout/page';
import { InvoiceLinesStatus } from '../../../types/invoice-line/status.ts';

const schema = z.object({
	status: z.string(),
});
type Schema = z.infer<typeof schema>;

const Detail: FC<{ id: number }> = ({ id }) => {
	const ref =
		useRef<AgGridReact<GetOrganizationsIdInvoicesInvoiceLines200DataItem>>(
			null,
		);
	const { data: organization } = useGetOrganizationsId(id, {
		query: {
			suspense: true,
			select: (data) => data.data,
		},
	});
	const { data: invoiceLineStatuses } = useGetInvoicesInvoiceLinesStatuses(
		{},
		{
			query: {
				select: (data) => data.data,
			},
		},
	);
	const { mutateAsync } = usePutInvoicesInvoiceLinesStatuses();

	const onErrorHandler = (
		error: AxiosError<
			| PutInvoicesInvoiceLinesStatuses400
			| PutInvoicesInvoiceLinesStatuses403
			| PutInvoicesInvoiceLinesStatuses500
		>,
	) => {
		if (error.response?.data.code === 'INVALID_STATUS') {
			sonner.error(
				'One or more invoice lines have an invalid status, please review your selection',
			);

			return;
		}

		sonner.error(
			'A unknown error occured while trying to update the invoice lines',
		);
	};
	const onSuccessHandler = (message: string) => {
		sonner.success(message);

		ref.current?.api.deselectAll();
		ref.current?.api.refreshServerSide();
		form.reset();
	};

	const form = useForm<Schema>({
		resolver: zodResolver(schema),
		defaultValues: { status: '' },
	});
	const onSubmit: SubmitHandler<Schema> = async (data) => {
		if (!ref.current) {
			sonner.error('Unknown issue occured while trying to submit the form');
			return;
		}

		const { selectAll, toggledNodes } =
			ref.current.api.getServerSideSelectionState() as IServerSideSelectionState;

		if (selectAll === false && toggledNodes.length === 0) {
			sonner.error('Please select at least one invoice line to update');
			return;
		}

		if (selectAll === true && ref.current.api.paginationGetRowCount() === 0) {
			sonner.error(
				'Please make sure your filters return at least one invoice line to update',
			);
			return;
		}

		if (selectAll === true) {
			const filterModel = ref.current.api.getFilterModel();
			const { data: invoiceLines } =
				await getOrganizationsIdInvoicesInvoiceLines(id, {
					page: 1,
					size: ref.current.api.paginationGetRowCount(),
					ledger:
						filterModel && 'ledger' in filterModel
							? filterModel.ledger.values
							: undefined,
					reference:
						filterModel && 'reference' in filterModel
							? filterModel.reference.filter
							: undefined,
					status:
						filterModel && 'status' in filterModel
							? filterModel.status.values
							: undefined,
				});

			const dataToUpdate = invoiceLines.data.map((invoiceLine) => ({
				id: invoiceLine.id,
				statusId: parseInt(data.status),
			}));

			await mutateAsync(
				{ data: dataToUpdate },
				{
					onError: onErrorHandler,
					onSuccess: () =>
						onSuccessHandler('All invoice lines have been updated'),
				},
			);

			return;
		}

		const dataToUpdate = toggledNodes.map((nodeId) => ({
			id: parseInt(nodeId),
			statusId: parseInt(data.status), // Coerce this value to a number with zod
		}));

		await mutateAsync(
			{ data: dataToUpdate },
			{
				onError: onErrorHandler,
				onSuccess: () =>
					onSuccessHandler('Selected invoice lines have been updated'),
			},
		);
	};
	const onInvalid: SubmitErrorHandler<Schema> = (fieldErrors) => {
		if ('status' in fieldErrors) {
			sonner.error('Please fill in the status field');
			return;
		}

		sonner.error('Form can not be submitted due to a unknown reason');
	};

	return (
		<>
			<Helmet title="Organizations" />
			{form.formState.isSubmitting && <Overlay>Loading...</Overlay>}
			<Page
				title={`${organization?.data.name} (${organization?.data.orgNumber})`}
			>
				<div className="flex-1">
					<Table id={id} gridRef={ref} />
				</div>
				<div className="bg-white flex items-center justify-end min-h-16 mt-2 rounded-md border shadow-sm px-3">
					<Form {...form}>
						<form
							onSubmit={form.handleSubmit(onSubmit, onInvalid)}
							className="flex gap-2 items-center"
						>
							<FormField
								control={form.control}
								name="status"
								render={({ field }) => (
									<FormItem>
										<Select
											{...field}
											onValueChange={field.onChange}
											defaultValue={field.value}
										>
											<FormControl>
												<SelectTrigger className="w-[200px]">
													<SelectValue placeholder="Select a status" />
												</SelectTrigger>
											</FormControl>
											<SelectContent>
												{invoiceLineStatuses?.data
													.filter(
														(status) =>
															status.code !== InvoiceLinesStatus.Invoiced &&
															status.code !== InvoiceLinesStatus.New,
													)
													.map((status) => (
														<SelectItem
															key={status.id}
															value={status.id.toString()}
														>
															{status.description}
														</SelectItem>
													))}
											</SelectContent>
										</Select>
									</FormItem>
								)}
							/>
							<Button className="min-w-[125px]">Update</Button>
						</form>
					</Form>
				</div>
			</Page>
		</>
	);
};

export default Detail;
